<template>
  <div>
    <el-header class="clearfix" v-show="collapsed">
      <div class="logo fl">Data Talks</div>
      <div class="h-nav">
          <el-menu class="el-icon-connection" mode="horizontal" background-color="#1890ff" text-color="#fff"
              active-text-color="#ffd04b" :default-active="activeIndex" @select="handleSelect">
              <el-menu-item index="index">首页</el-menu-item>
              <el-menu-item index="dashborad">数据大盘</el-menu-item>
              <el-menu-item index="pinpai-graph">数据资产</el-menu-item>
              <el-menu-item index="profiles4">数据画像</el-menu-item>
              <el-menu-item index="AlgorithmsOverview">模型与算法</el-menu-item>
              <el-menu-item index="dataStrategy">策略与管控</el-menu-item>
              <el-menu-item index="dataReport1">数据报告</el-menu-item>
              <el-menu-item index="dataResearch">数据探索</el-menu-item>
          </el-menu>
      </div>
    </el-header>
    <a-button type="primary" class="head-btn" @click="collapsed = !collapsed">
      <a-icon :type="collapsed ? 'menu-unfold' : 'menu-fold'" />
    </a-button>
  </div>
</template>

<script>
export default {
  name: "Header",
  data() {
    return {
      collapsed: true,
      activeIndex: "index",
      indexArr: ["dashborad", "pinpai-graph", "profiles4", "AlgorithmsOverview", "dataStrategy", "dataReport1", "dataResearch"],
    };
  },
  mounted() {
    this.activeIndex = this.$route.name;
    if (this.activeIndex !== "index") {
      this.$parent.navActive = this.indexArr.indexOf(this.activeIndex);
    }
  },
  methods: {
    handleSelect(key, keyPath) {
      
      if (key !== "index") {
        this.$parent.navActive = this.indexArr.indexOf(key);
      }

      this.$router.push({
        path: '/'+key
      });
    },
    onNavHeader(ev) {
      console.log(ev.key);
      
      console.log(this.$parent.navActive);

      let navType = ev.$attrs.type
      this.current = ev.$attrs['data-index'];

      this.navActive = ev.$attrs['data-index']
      this.defaultActive = navType;

      this.$router.push({
        path: '/'+navType
      });

      // if (navType == 'data-dashboard') {
      //     this.navActive = 0
      //     this.$router.push({
      //         path: '/dataDashboard'
      //     })
      //     this.defaultActive = 'data-dashboard'
      // } else if (navType == 'data-assets') {
      //     this.navActive = 1
      //     this.$router.push({
      //         path: '/pinpai-graph'
      //     })
      //     this.defaultActive = 'pinpai-graph'
      // } else if (navType == 'data-profiles') {
      //     this.navActive = 2
      //     this.$router.push({
      //         path: '/profiles4'
      //     })
      //     this.defaultActive = 'profiles4'
      // } else if (navType == 'data-algorithms') {
      //     this.navActive = 3
      //     this.$router.push({
      //         path: '/Algorithms-Overview'
      //     })
      //     this.defaultActive = 'Algorithms-Overview'
      // } else if (navType == 'data-in-charge') {
      //     this.navActive = 4
      //     this.$router.push({
      //         path: '/configure'
      //     })
      //     this.defaultActive = 'configure'
      // } else if (navType == 'data-reports') {
      //     this.navActive = 5
      //     this.$router.push({
      //         path: '/dataReport1'
      //     })
      //     this.defaultActive = 'dataReport1'
      // }else if (navType == 'data-research') {
      //       this.navActive = 6
      //       this.$router.push({
      //           path: '/dataResearch'
      //       })
      //       this.defaultActive = 'dataResearch'
      //   }
    }
  }
};
</script>

<style scoped>
.el-header {
  overflow: hidden;
  background-color: #1890ff;
}

.logo {
  width: 100px;
  height: 40px;
  font-size: 19px;
  margin-top: 14px;
  font-weight: 600;
  background-size: contain;
  float: left;
  color: #fff;
}
.el-menu {
  height: 100%;
}

.h-nav {
  float: left;
  margin-left: 10%;
}

.head-btn{
  position: fixed;
  right:10px;
  top:10px;
  z-index: 1000;
}

</style>

<!--  -->
<template>
    <div class="main">
        <Headers />
        <el-container>
            <el-aside :class="isCollapse ? 'el-aside-open' : 'el-aside'">
                <el-menu :default-openeds="defaultOpeneds" :default-active="defaultActive" :router="router"
                    class="el-menu-vertical-demo" @open="handleOpen" @close="handleClose" :collapse="isCollapse"
                    @select="selectmenu">
                    <el-submenu v-if="navActive == 1" index="1">
                        <template slot="title">
                            <i class="el-icon-location"></i>
                            <span slot="title">数据资产</span>
                        </template>
                        <el-menu-item-group>
                            <el-submenu index="1-1">
                                <template slot="title">数据地图</template>
                                <el-menu-item index="pinpai-graph">品牌-图谱</el-menu-item>
                                <el-menu-item index="shichang-graph">市场-图谱</el-menu-item>
                                <el-menu-item index="kehu-graph">客户-图谱</el-menu-item>
                                <el-menu-item index="xiaofeizhe-graph">消费者-图谱</el-menu-item>
                                <el-menu-item index="company-graph">公司-图谱</el-menu-item>
                            </el-submenu>
                            <el-menu-item index="data-standard">数据标准</el-menu-item>
                            <el-menu-item index="dataElt">数据处理</el-menu-item>
                        </el-menu-item-group>
                    </el-submenu>

                    <el-submenu index="2" v-if="navActive == 2">
                        <template slot="title">
                            <i class="el-icon-location"></i>
                            <span slot="title">数据画像</span>
                        </template>
                        <el-menu-item-group>
                            <el-submenu index="2-1">
                                <template slot="title">品牌画像</template>
                                <el-menu-item index="profiles1">profiles-1</el-menu-item>
                                <el-menu-item index="profiles2">profiles-2</el-menu-item>
                            </el-submenu>

                            <el-submenu index="2-2">
                                <template slot="title">市场画像</template>
                                <el-menu-item index="profiles3">profiles-3</el-menu-item>
                                <el-menu-item index="profiles4">profiles-4</el-menu-item>
                            </el-submenu>
                            <el-menu-item index="uploadExcel">数据管理</el-menu-item>
                        </el-menu-item-group>
                    </el-submenu>

                    <el-submenu index="3" v-if="navActive == 3">
                        <template slot="title">
                            <i class="el-icon-location"></i>
                            <span slot="title">模型与算法</span>
                        </template>
                        <el-menu-item-group>
                            <el-menu-item index="Algorithms-Overview">模型概述</el-menu-item>

                            <el-submenu index="3-2">
                                <template slot="title">业务场景</template>
                                <el-menu-item index="data-metrics">数据指标</el-menu-item>
                                <el-menu-item index="long-time-forecast">中长期预测</el-menu-item>
                                <el-menu-item index="year-based-forecast">年度预测</el-menu-item>
                                <el-menu-item index="half-year-based-forecast">半年度预测</el-menu-item>
                                <el-menu-item index="month-based-forecast">月度预测</el-menu-item>
                            </el-submenu>



                            <el-submenu index="3-3">
                                <template slot="title">模型说明</template>
                                <el-menu-item index="1-Regression-Model">回归模型</el-menu-item>
                                <el-menu-item index="2-Gray-Model">灰色模型</el-menu-item>
                                <el-menu-item index="3-EWMA-Model">加权平均模型</el-menu-item>
                                <el-menu-item index="4-ARIMA-Model">时间序列模型</el-menu-item>
                                <el-menu-item index="4-ARIMA-Model">季节指数模型</el-menu-item>
                                <el-menu-item index="4-ARIMA-Model">比重推算模型</el-menu-item>
                                <el-menu-item index="5-TimeSeries-Model">时间序列模型</el-menu-item>
                            </el-submenu>
                            <el-menu-item index="model-flow">模型编排</el-menu-item>
                            <el-menu-item index="model-design">模型设计</el-menu-item>
                        </el-menu-item-group>
                    </el-submenu>

                    <el-submenu index="4" v-if="navActive == 4">
                        <template slot="title">
                            <i class="el-icon-location"></i>
                            <span slot="title">策略与管控</span>
                        </template>
                        <el-menu-item-group index="4-1">
                            <el-menu-item index="introductionOverview">使用说明</el-menu-item>
                            <el-menu-item index="platformOverview">平台概况</el-menu-item>
                        </el-menu-item-group>
                    </el-submenu>


                    <el-submenu index="5" v-if="navActive == 5">
                        <template slot="title">
                            <i class="el-icon-location"></i>
                            <span slot="title">数据报告</span>
                        </template>
                        <el-menu-item-group index="5-1">
                            <el-menu-item index="dataReport1">报告模板</el-menu-item>
                        </el-menu-item-group>
                    </el-submenu>




                </el-menu>
            </el-aside>
            <el-main class="ui-view">
                <div class="" style="background-color: #fff; height: 100%; overflow-y: auto">
                    <router-view></router-view>
                </div>
            </el-main>
        </el-container>
    </div>
</template>
<script>
import '../../assets/css/reset.css'
import Headers from '../../components/Header'
export default {
    components: {
        Headers
    },
    data() {
        return {
            router: true,
            isCollapse: false,
            activeIndex: 1, //默认颜色,
            navActive: 1,
            defaultActive: 'pinpaiGraph',
            defaultOpeneds: ['1', '1-1', '3', '2', '2-1', '6'],
            indexArr: ["dashborad", "pinpai-graph", "profiles4", "AlgorithmsOverview", "dataStrategy", "dataReport1", "dataResearch"],
        }
    },
    created() { },
    mounted() {
        console.log(this.$route);
        this.navActive = this.indexArr.indexOf(this.$route.name);
    },
    //计算属性
    computed: {},
    //函数
    methods: {
        selectmenu(index, indexPath) {
            console.log(index, indexPath)
            this.activeMenu = index
            // window.localStorage.setItem('activeMenu', this.activeMenu)
        },
        onNavSwitch() {
            this.isCollapse = !this.isCollapse
        },
        handleOpen(key, keyPath) {
            console.log(key, keyPath)
        },
        handleClose(key, keyPath) {
            console.log(key, keyPath)
        },


        //左侧导航的判断     
        onNavAside() {
            console.log(this.$route.path)
            let route_path = this.$route.path
            if (route_path == '/2-Gray-Model') {
                this.navActive = 3
                this.activeIndex = 3   //  设置顶部导航的active 的方式——获取与设置，然后走重新渲染即可！ 直接需改数据，貌似不能够重新渲染~
                this.defaultActive = 'Algorithms-Overview'
                console.log(this.$route.path)
            }

        },
    },
    //监控
    watch: {}
}
</script>
<style scoped>
.main {
    height: 100%;
}

.el-container {
    width: 100%;
    height: 100%;
}

.el-header {
    background-color: #1890ff;
}

.logo {
    width: 100px;
    height: 40px;
    font-size: 19px;
    margin-top: 14px;
    font-weight: 600;
    background-size: contain;
    float: left;
    color: #fff;
}

.nav-switch {
    float: left;
    width: 80px;
    height: 80px;
    color: #fff;
    font-size: 24px;
    margin-top: 17px;
}

.el-aside {
    width: 200px !important;
}

.el-aside-open {
    width: 4% !important;
}

.el-menu {
    height: 100%;
}

.h-nav {
    float: left;
    margin-left: 10%;
}

.el-menu-demo {
    background-color: #1890ff !important;
}

.ui-view {
    overflow-y: auto;
    background-color: #f0f2f5;
    padding: 20px;
}

.nav-a a {
    color: #303133;
    text-decoration: none;
}
</style>
  